import React, { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import {Cell, QuickNavTitle} from "../components";
import "./gridPage.css";
import "./searchPage.css";
import {stringVariableExpand} from "../utils/stringUtils";
import * as PropTypes from "prop-types";
import {DropdownItem} from "reactstrap";

function SearchResult(props) {
    if (props.result && props.result.length && props.result[0].data) {
        return <div className={"searchResult updated"}>
            {
                ( props.result.map((item, index) => {
                    return ( item.size > 0 &&
                        <>
                            <h6>{item.Heading}</h6>
                            <SearchList result={item.data} urlTemplate={props.urlTemplate} />
                        </>
                    )
                }))

            }
        </div>
    } else {
        return (
                <div className={"searchResult "}>
                    Loading...
                </div>
        )
    }
}

SearchResult.propTypes = {
    result: PropTypes.array,
    urlTemplate: PropTypes.string
};

function SearchList(props) {
    if (props.result!==null) {
        return (
            <div className="searchList">
                <div className="wrapper">
                    {
                        (props.urlTemplate && props.result && props.result.length && (props.result.map((item, index) => {
                            let link = stringVariableExpand(props.urlTemplate, {
                                AliasId: item.AliasId,
                                CategoryId: item.CategoryId,
                                CellId: item.CellId
                            });
                            return <Cell key={index} item={item} title={item.Category} linkTo={link} type={"stamp"}/>
                        }))) || <div>Nothing found!</div>
                    }
                </div>
            </div>
        )
    } else {
        return <div>Loading...</div>
    }
}

SearchList.propTypes = {
    result: PropTypes.array,
    urlTemplate: PropTypes.string
};

function UpdatedPage(props) {
    let { id } = useParams();

    const [title, setTitle] = useState(null);
    const [urlTemplate, setUrlTemplate] = useState();
    const [response, setResponse] = useState(null);
    useEffect(() => {
        let url = "/api/updated/" + id;
        console.log("url: " + url);
        fetch(url)
            .then(res => res.json())
            .then(res => {
                setUrlTemplate(res.urlTemplate);
                setTitle(res.Alias);
                setResponse(res.data);
            })
            .catch(console.error)
    }, [id]);
    return (
        <div className={"updated"}>
            <QuickNavTitle
                title={title}
                titleLink={"/grid/" + id}
                toPrev={""}
                subTitle={"Updated"}
                toNext={""}>
                {
                    (id &&
                        <>
                            <DropdownItem><Link to={response && "/about/" + id}>About</Link></DropdownItem>
                            <DropdownItem><Link to={response && "/search/alias?filter=associated&id=" + id}>Associated</Link></DropdownItem>
                            <DropdownItem><Link to={response && "/grid/" + id}>Subjects</Link></DropdownItem>
                        </>
                    )
                }
            </QuickNavTitle>
            <SearchResult result={response} urlTemplate={urlTemplate} />
        </div>
    );
}

export default UpdatedPage;