import React, {useEffect, useState} from "react";
import {Link, NavLink, useLocation, useParams, withRouter} from "react-router-dom";
import "./searchInputField.css";
import {stringVariableExpand} from "../utils/stringUtils";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SearchInputField(props) {
    let { range, key } = useParams();
    let query = useQuery();
    const [searchKey, setSearchKey] = useState(key);
    const [searchLink, setSearchLink] = useState("/");
    const [refine, setRefine] = useState(false);
    const [filterArgs, setFilterArgs] = useState("");
    const [sectors, setSectors] = useState();
    const [countries, setCountries] = useState();
    const [filters, setFilters] = useState({
        country: query.get("country"),
        sector: query.get("sector"),
        filter: query.get("filter"),
        aliasId: query.get("id")
    });
    const onClickClearHandler = event => {
        event.preventDefault();
        let searchKeyInput = document.getElementById("searchKeyInput");
        searchKeyInput.focus();
        setSearchKey("");
    }
    const onSearchKeyChangeHandler = event => {
        setSearchKey(event.target.value);
    }
    const go = () => {
        document.getElementById("goButtonATag").click();
        document.getElementById("searchKeyInput").focus();
    }
    const onKeyPressHandler = event => {
        if (event.charCode === 13) {
            event.preventDefault();
            go();
        }
    }
    const refineClickHandler = event => {
        event.preventDefault();
        setRefine(!refine);
        document.getElementById("refine").className = refine;
    }
    const setSectorHandler = (newSector, event) => {
        event.preventDefault();
        setFilters({
            country: filters.country,
            sector: newSector,
            filter: filters.filter,
            aliasId: filters.aliasId
        });
        setRefine(false);
    }
    const setCountryHandler = (newCountry, event) => {
        event.preventDefault();
        setFilters({
            country: newCountry,
            sector: filters.sector,
            filter: filters.filter,
            aliasId: filters.aliasId
        });
        setRefine(false);
    }
    useEffect( () => {
        if (range === "featured") {
            console.log("range = featured => reset");
            range = "post"; //setRange("post");
            setFilters({
                country: null,
                sector: null,
                filter: null,
                aliasId: null
            });
        }
    }, [range]);
    useEffect(() => {
        // used for refine filters
        if (searchLink !== "/" && range !== "featured" && document.getElementById("searchKeyInput") !== document.activeElement) {
            console.log("forced go - " + range);
            go();
        }
    }, [filterArgs])    // do not add searchLink to deps
    useEffect(() => {
        if (filters.country || filters.sector || filters.filter || filters.aliasId) {
            setFilterArgs(stringVariableExpand("?country=${country}&sector=${sector}&filter=${filter}&id=${aliasId}", filters) );
        } else {
            setFilterArgs("");
        }
        console.log("filterArgs: " + filterArgs);
    }, [filters])
    useEffect(() => {
        let json = {
            range: range === "featured" ? "post" : range,
            key: searchKey !== undefined ? searchKey : "",
        }
        console.log("json: " + JSON.stringify(json));
        let url = stringVariableExpand("/search/${range}", json);
        if (searchKey !== undefined && searchKey !== "") {
            url += stringVariableExpand("/${key}", json);
        }
        console.log("url: " + url);
        setSearchLink(() => {
            return url
        });
        console.log("searchLink url: " + searchLink);
    }, [range, searchKey])
    useEffect(() => {
        console.log("Read sectors");
        fetch("/api/sectors")
            .then(res => res.json())
            .then(res => setSectors(res.data))
            .catch(console.error)
    }, [])  // do once
    useEffect(() => {
        console.log("Read countries");
        fetch("/api/countries")
            .then(res => res.json())
            .then(res => setCountries(res.data))
            .catch(console.error)
    }, [])  // do once
    return (
        <div className="search">
            <div>
                <input id={"searchKeyInput"} data-testid={"searchKeyInput"} className={"search"} type={"text"}
                       value={searchKey}
                       placeholder={"Search or click Go to view all"}
                       onChange={onSearchKeyChangeHandler}
                       onKeyPress={onKeyPressHandler}
                />
                <div className={"clear"}>
                    <a href={"/"} onClick={onClickClearHandler}>
                        <ion-icon className={"clear"} name={"close-circle-outline"} />
                    </a>
                </div>
                <div id={"goButton"}>
                <Link id="goButtonATag" to={searchLink + filterArgs}>Go</Link>
                </div>
            </div>
            <div className={"ranges"}>
                <NavLink to={"/search/alias" + filterArgs}>Brand</NavLink>
                <NavLink to={"/search/subject/" + filterArgs}>Subject</NavLink>
                <NavLink to={"/search/post/" + filterArgs}>Post</NavLink>
            </div>
            <div id={"refine"} className={"" + refine}>
                <button className={"button"} onClick={refineClickHandler}>Refine</button>
                {
                    (filters.sector && <button onClick={(e) => setSectorHandler(null, e)}><span className={"refinement"}>{filters.sector}</span></button>)
                }
                {
                    (filters.country && <button onClick={(e) => setCountryHandler(null, e)}><span className={"refinement"}>{filters.country}</span></button>)
                }
                <div className={"refine drawer"}>
                    <div className={"refinements"}><h5>Business Type</h5>
                        {
                            (sectors && sectors.length && ( sectors.map((item, key) => {
                                return <button key={key} onClick={(e) => setSectorHandler(item.Sector, e)}>{item.Sector}</button>
                            })))
                        }
                    </div>
                    <div className={"refinements"}><h5>Country</h5>
                        {
                            (countries && countries.length && ( countries.map((item, key) => {
                                return <button key={key} onClick={(e) => setCountryHandler(item.Name, e)}>{item.Name}</button>
                            })))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(SearchInputField);