import React, { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import {Cell, QuickNavTitle} from "../components";
import "./gridPage.css"
import {DropdownItem} from "reactstrap";

function GridPage(props) {
    let { id } = useParams();
    const [response, setResponse] = useState();
    useEffect( () => {
        const root = document.getElementById("root");
        root.scrollIntoView({
            behaviour: 'smooth'
        }, 500)
    }, [id]);
    useEffect(() => {
        fetch("/api/grid/" + id)
            .then(res => res.json())
            .then(res => {
                setResponse(res.data);
            })
            .catch(console.error)
    }, [id]);
    return (
        <div className="grid">
            <QuickNavTitle
                title={response && response.length && response[0].Alias}
                titleLink={response && response.length && ( "/grid/" + response[0].AliasId )}
                toPrev={""}
                subTitle={"Subjects"}
                toNext={""}>
                <DropdownItem><Link to={response && response.length && "/about/" + response[0].AliasId}>About</Link></DropdownItem>
                <DropdownItem><Link to={response && response.length && "/updated/" + response[0].AliasId}>Updated</Link></DropdownItem>
                <DropdownItem><Link to={response && response.length && "/search/alias?filter=associated&id=" + response[0].AliasId}>Associated</Link></DropdownItem>
                <DropdownItem><Link to={response && response.length && "/grid/" + response[0].AliasId}>Subjects</Link></DropdownItem>
            </QuickNavTitle>
            <div className="container">
                <div className="gridList">
                    {
                        ( response && response.length && ( response.map((item, index) => {
                            return <Cell key={index} item={item} title={item.Category} linkTo={"/grid/" + id + "/" + item.CategoryId} type={"thumbnail"} />
                        })) ) || "Loading..."
                    }
                </div>
            </div>
        </div>
    );
}

export default GridPage;