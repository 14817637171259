import * as PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

import "./quickNavTitle.css"

function QuickNavTitle(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const infoLink = process.env.REACT_APP_INFO_LINK || "/grid/22"
    return (
        <div className={"quickNavTitle"}>
            <div className={"titleSection"}>
                <div className={"left"}>&nbsp;</div>
                <h2 className={"middle"}>
                    <Link to={props.titleLink}>
                        <span>{props.title}</span>
                    </Link>
                </h2>
                <div className={"right"} >
                    <ul className="">
                        <li className={""}>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle tag="span">
                                    <ion-icon data-testid={"iconMenu"} name={"menu-sharp"} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {props.children}
                                    {React.Children.count(props.children) > 0 &&
                                        <DropdownItem divider/>
                                    }
                                    <DropdownItem><Link to={infoLink}>Sheerd info</Link></DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </div>
            {
                ( (props.toPrev || props.subTitle || props.toNext) &&
            <div className={"subtitleSection"}>
                <div>
                    <div className={"prev"}>
                        {
                            ( props.toPrev && props.toPrev !== "" &&
                                <Link id="prevBtn" to={props.toPrev}>
                                    <span>&lt;</span>
                                </Link>
                            )
                        }
                    </div>
                </div>
                <h6>
                    <span>{props.subTitle}</span>
                </h6>
                <div>
                    <div className={"next"}>
                        {
                            ( props.toNext && props.toNext !== "" &&
                                <Link id="nextBtn" to={props.toNext}>
                                    <span>&gt;</span>
                                </Link>
                            )
                        }
                    </div>
                </div>
            </div>
                )
            }
        </div>
    );
}

QuickNavTitle.propTypes = {
    title: PropTypes.string,
    titleLink: PropTypes.string,
    toNext: PropTypes.string,
    toPrev: PropTypes.string,
    subTitle: PropTypes.string
};

export default withRouter(QuickNavTitle);