import React, { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import {CellList, QuickNavTitle} from "../components";
import "./aboutPage.css";
import {DropdownItem} from "reactstrap";

function AboutPage(props) {
    let { id } = useParams();
    const [followers, setFollowers] = useState(false);
    const [response, setResponse] = useState(false);
    useEffect( () => {
        const root = document.getElementById("root");
        root.scrollIntoView({
            behaviour: 'smooth'
        }, 500)
    }, [id]);
    useEffect(() => {
        fetch("/api/about/" + id)
            .then(res => res.json())
            .then(res => {
                setResponse(res.data);
                setFollowers(res.followers);
            })
            .catch(console.error)
    }, [id]);
    return (
        <div className="aboutView">
            <QuickNavTitle
                title={ (response && response.length > 0 && response[0].Alias) || ""}
                titleLink={ (response && response.length > 0 && ( "/grid/" + response[0].AliasId ) ) || "/" }
                toPrev={""}
                subTitle={""}
                toNext={""}>
                <DropdownItem><Link to={response && response.length && "/updated/" + response[0].AliasId}>Updated</Link></DropdownItem>
                <DropdownItem><Link to={response && response.length > 0 && "/search/alias?filter=associated&id=" + response[0].AliasId}>Associated</Link></DropdownItem>
                <DropdownItem><Link to={response && response.length > 0 && "/grid/" + response[0].AliasId}>Subjects</Link></DropdownItem>
            </QuickNavTitle>
            <div className="container">
                <CellList itemArray={response} linkPrefix={"/grid/"} linkItemField={"AliasId"} emptyMessage={"About data is missing"} />
                { (followers && followers.length > 0) &&
                    <div className="followerList divide">
                        <Link to={response && response.length > 0 && "/search/alias?filter=associated&id=" + response[0].AliasId}><h5>Associated</h5></Link>
                        <CellList itemArray={followers} linkPrefix={"/grid/"} linkItemField={"AliasId"}
                                  emptyMessage={"No associated"} type={"stamp"}/>
                    </div>
                }
            </div>
        </div>
    );
}

export default AboutPage;