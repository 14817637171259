import React, { useState, useEffect } from "react";
import {Cell, QuickNavTitle} from "../components";
import "./home.css";

export function getBackendData(callback: { (data: string[]): void }) {
    fetch("/api/recommended")
        .then(res => res.json())
        .then(res => {
            console.log(res.data);
            callback(res.data);
        })
        .catch(console.error)
}

function Home(props: any) {
    const typeArray = ['fullWidth', 'thumbdesc', 'fullWidth', 'thumbdesc', 'fullWidth', 'thumbdesc', 'thumbdesc', 'fullWidth', 'thumbdesc', 'fullWidth', 'thumbdesc', 'thumbdesc', 'fullWidth', 'thumbdesc', 'fullWidth']
    const [response, setResponse] = useState<string[]>([]);
    useEffect(() => {
        getBackendData((data: string[]) => setResponse(data));
    }, []);
    return (
        <div data-testid={"homepage"} className="home">
            <QuickNavTitle
                title="Sheerd" //{response && response[0].Alias}
                titleLink={"/"}
                toPrev={""}
                subTitle={""}
                toNext={""}>
            </QuickNavTitle>
            <div className="container">
                <div className="recommendedList">
                    {
                        ( response!==undefined && response.length && ( response.map((item, index) => {
                            const type = index < typeArray.length ? typeArray[index] : "stamp";
                            return <Cell key={index} item={item}  type={type} />
                        })) ) || "Loading..."
                    }
                </div>
            </div>
        </div>
    );
}

export default Home;