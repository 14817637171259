import React, { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import { Cell, QuickNavTitle } from "../components";
import "./gridPage.css"
import {DropdownItem} from "reactstrap";

function SubjectPage(props) {
    let { id, subject } = useParams();
    const [response, setResponse] = useState();
    const [prevLink, setPrevLink] = useState("");
    const [nextLink, setNextLink] = useState("");
    useEffect(() => {
        fetch("/api/grid/" + id + "/" + subject)
            .then(res => res.json())
            .then(res => {
                setResponse(res.data);
                if (res.prevCategoryId !== -1) {
                    setPrevLink("/grid/" + res.data[0].AliasId + "/" + res.prevCategoryId);
                } else {
                    setPrevLink("");
                }
                if (res.nextCategoryId !== -1) {
                    setNextLink("/grid/" + res.data[0].AliasId + "/" + res.nextCategoryId);
                } else {
                    setNextLink("");
                }
            })
            .catch(console.error)
    }, [id, subject]);
    return (
        <div className="subject grid">
            <QuickNavTitle
                title={response && response.length && response[0].Alias}
                titleLink={response && response.length && ( "/grid/" + response[0].AliasId )}
                toPrev={prevLink}
                subTitle={response && response.length && response[0].Category}
                toNext={nextLink}>
                <DropdownItem><Link to={response && response.length && "/about/" + response[0].AliasId}>About</Link></DropdownItem>
                <DropdownItem><Link to={response && response.length && "/updated/" + response[0].AliasId}>Updated</Link></DropdownItem>
                <DropdownItem><Link to={response && response.length && "/search/alias?filter=associated&id=" + response[0].AliasId}>Associated</Link></DropdownItem>
                <DropdownItem><Link to={response && response.length && "/grid/" + response[0].AliasId}>Subjects</Link></DropdownItem>
            </QuickNavTitle>
            <div className="container">
               <div className="gridList">
                    {
                        ( response && response.length && ( response.map((item, index) => {
                            return <Cell key={index} item={item} linkTo={"/cell/" + item.CellId} title={item.Title} type={"thumbnail"} />
                        })) ) || "Loading..."
                    }
                    <div className="clear"></div>
                </div>
            </div>
        </div>
    );
}

export default SubjectPage;