import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./navigation.css";

function Navigation(props) {
    const version = process.env.REACT_APP_VERSION || "missing";
    return (
        <div data-testid={"navigation"} className="navigation">
            <nav className={"top"}>
                <div className={"left"}>
                    <ul>
                        <li data-testid={"home"}>
                            <Link className="" to="/">
                                <ion-icon name={"home-outline"} />
                            </Link>
                        </li>
                        <li data-testid={"search"}>
                            <Link className="" to="/search/featured">
                                <ion-icon name={"search-outline"} />
                            </Link>
                        </li>
                    </ul>
                </div>

            </nav>
            <span className={"hidden"}>version: {version}</span>
        </div>
    );
}

export default withRouter(Navigation);