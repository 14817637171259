import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./cell.css";
import "./cell_media.css";
import LazyLoad from "react-lazy-load";
import { ExpandableText } from "./@components";

function Cell(props) {
    const domain_name = process.env.REACT_APP_DOMAIN_NAME || "sheerd.com";
    if ( ['stamp', 'thumbnail'].includes(props.type) ) {
        return (
            <div className={"cell " + props.type} key={props.key}>
                <Link to={props.linkTo || "/cell/" + props.item.CellId}>
                    <div className="imageContainer">
                        {
                            ( props.item.ImageUrl &&
                                    <LazyLoad offset="500" once>
                                        <img className="mainImage" alt={props.item.Title} src={props.item.ImageUrl.replace("http:", "https:") + "=s600"}/>
                                    </LazyLoad>
                            ) || <div className={"mainImage"}><div className={"imagePlaceHolder"}>T</div></div>
                        }
                    </div>
                <div className={"text"}>
                    <h5>{props.title || props.item.Title}</h5>
                </div>
                </Link>
            </div>
        );
    } else {
        return (
            <div className={"cell " + props.type} key={props.key}>
                {
                    ( !["single", "about"].includes(props.type) &&
                        <h2>
                            <img className="imageIcon" alt={"Sheerd logotype"} src={"/Sheerd_logo_black.png"}/>
                            <Link to={"/grid/" + props.item.AliasId}>{props.item.Alias}</Link>
                        </h2>
                    )
                }
                <div className={"imageContainer"}>
                    <Link className={"imageLink"} to={props.linkTo || "/cell/" + props.item.CellId}>
                        {
                            ( props.item.ImageUrl &&
                                <LazyLoad offset={500} once>
                                    <img className={"mainImage"} alt={props.item.Title} src={props.item.ImageUrl.replace("http:", "https:") + "=s600"}/>
                                </LazyLoad>
                            ) || <div className={"imagePlaceHolder"}>T</div>
                        }
                    </Link>
                </div>
                <div className={"text"}>
                    <div className={"split"}>
                        <div className={"left"}>
                            {
                                ( props.item.Category === "About" &&
                                    <h5>{props.item.Category}</h5>
                                )
                            }
                            {
                                ( props.item.CategoryId &&
                                    <Link to={"/grid/" + props.item.AliasId + "/" + props.item.CategoryId}>
                                        <h5>{props.item.Category}</h5>
                                    </Link>
                                )
                            }
                            {
                                (props.item.CellId &&
                                    <Link to={"/cell/" + props.item.CellId}>
                                        <h4>{props.item.Title}</h4>
                                    </Link>
                                )
                            }
                        </div>
                        <div className={"right"}>
                            {
                                ( props.type === "single" && props.item.LinkUrl &&
                                    <a className={"button"} alt={props.item.LinkText} href={props.item.LinkUrl} target={props.item.LinkUrl}>Website</a>
                                )
                            }
                        </div>
                    </div>
                    <ExpandableText text={props.item.Text} link={props.linkTo || "/cell/" + props.item.CellId} />
                    {
                        (props.type === "single" &&
                            <div className={"shareSection split divide"}>
                                <div className={"left"}>
                                    <h5>SHARE</h5>
                                    <div className={"social"}>
                                        <div className="fb-share-button" data-href={"https://" + domain_name + "/cell/" + props.item.CellId}
                                             data-layout="button" data-size="small">
                                            <a target={"facebook_share_" + props.item.CellId}
                                                 href={"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F" + domain_name + "%2Fcell%2F" + props.item.CellId + "&amp;src=sdkpreparse"}
                                                 className="fb-xfbml-parse-ignore">
                                                <img src={"/f_logo_RGB-Grey_72.png"} alt={"Facebook logotype"} height={"36px"} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className={"right"}>
                                    <h5>POSTED</h5>
                                    <p>{new Date(props.item.Updated).toLocaleDateString('sv-SE')}</p>
                                </div>
                            </div>
                        )
                    }
                    {
                        ( props.type === "single" && props.item.Tags && props.item.Tags !== "" &&
                            <div className={"tagsSection split divide"}>
                                <div className={"left"}>
                                    <h5>RELATED TAGS</h5>
                                    <p>
                                        {
                                            props.item.Tags.split(' ').map((item, key) => {
                                                return <Link key={key} to={"/search/tags/" + item}>
                                                    <span className={"tag"} key={key}>{item}</span>
                                                </Link>
                                            })
                                        }
                                    </p>
                                </div>
                                <div className={"right"}>

                                </div>
                            </div>
                        )
                    }
                    {
                        ( props.type === "about" &&
                            <div className={"aboutSection"}>
                                {
                                    props.item.Website && (
                                        <div className={"split divide"}>
                                            <div> </div>
                                            <div className={"middle"}>
                                                <a className={"button"} href={props.item.Website} target={props.item.Website}>website</a>
                                            </div>
                                            <div> </div>
                                        </div>
                                    )
                                }
                                {
                                    props.item.Sector && (
                                        <div className={"split divide"}>
                                            <div>
                                                <h5>Business type</h5>
                                                <div>{props.item.Sector}</div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    props.item.Address && (
                                        <div className={"split divide"}>
                                            <div>
                                                <h5>Details</h5>
                                                <div>{props.item.Name}</div>
                                                <div>{props.item.Address}</div>
                                                <div>{props.item.ZipCode}&nbsp;{props.item.City}</div>
                                                <div>{props.item.Country}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}


export default withRouter(Cell);