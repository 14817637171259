import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation } from "./components";
import {Home, GridPage, SubjectPage, CellPage, AboutPage, SearchPage, UpdatedPage} from "./pages";

function App() {
    let touchStart = Date.now();
    let touchStartX = 0;
    let distance = 0;
    const handleTouchStart = (event) => {
        touchStartX = event.targetTouches[0].clientX;
        touchStart = Date.now();
    }
    const handleTouchMove = (event) => {
        distance = event.targetTouches[0].clientX - touchStartX;
    }
    const handleTouchEnd = () => {
        let threshold = 100;
        if ((Date.now() - touchStart) < 1000) {
            if (distance < -threshold) {
                let element = document.getElementById("nextBtn");
                element && element.click();
            }
            if (distance > threshold) {
                let element = document.getElementById("prevBtn");
                element && element.click();
            }
        }
        distance = 0;
    }
    return (
        <div id="App" className="App"
             onTouchStart={e => handleTouchStart(e)}
             onTouchMove={handleTouchMove}
             onTouchEnd={e => handleTouchEnd(e)}>
            <Router>
                <Navigation />
                <Switch>
                    <Route path={"/search"} exact component={() => <SearchPage header={"SearchPage"} />} />
                    <Route path={"/search/:range"} exact component={() => <SearchPage header={"SearchPage"} />} />
                    <Route path={"/search/:range/:key"} exact component={() => <SearchPage header={"SearchPage"} />} />
                    <Route path="/grid/:id" exact component={() => <GridPage header="GridPage" />} />
                    <Route path="/grid/:id/:subject" exact component={() => <SubjectPage header="GridPage" />} />
                    <Route path="/cell/:id" exact component={() => <CellPage header="Cell" />} />
                    <Route path="/about/:id" exact component={() => <AboutPage header="Cell" />} />
                    <Route path="/updated/:id" exact component={() => <UpdatedPage header="Updated" />} />
                    <Route path="" exact component={() => <Home header="Home" />} />
                </Switch>
            </Router>
        </div>
    );
}


export default App;
