import React, { useState, useEffect } from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {Cell, QuickNavTitle, SearchInputField} from "../components";
import "./gridPage.css";
import "./searchPage.css";
import {stringVariableExpand} from "../utils/stringUtils";
import * as PropTypes from "prop-types";
import {DropdownItem} from "reactstrap";

function SearchResult(props) {
    const sizeClassName = props.result && props.result.length%8<3 ? "few" : "alot";
    if (props.result && props.result.length && props.result[0].cells) {
        return <div className={"searchResult featured"}>
            {
                ( props.result.map((item, index) => {
                    return (
                        <div key={index}>
                            <h6>{item.Heading}</h6>
                            <SearchList result={item.cells} urlTemplate={props.urlTemplate} />
                        </div>
                    )
                }))

            }
        </div>
    } else if (props.result && props.result.length && props.result[0].data) {
        return <div className={"searchResult updated"}>
            {
                ( props.result.map((item, index) => {
                    return ( item.size > 0 &&
                        <div key={index}>
                            <h6>{item.Heading}</h6>
                            <SearchList result={item.data} urlTemplate={props.urlTemplate} />
                        </div>
                    )
                }))

            }
        </div>
    } else {
        return (
                <div className={"searchResult " + sizeClassName}>
                    {
                        (props.filter &&
                            <h6>{props.filter}</h6>
                        )
                    }
                    <SearchList result={props.result} urlTemplate={props.urlTemplate}/>
                </div>
        )
    }
}

SearchResult.propTypes = {
    result: PropTypes.array,
    urlTemplate: PropTypes.string
};

function SearchList(props) {
    if (props.result!==null) {
        return (
            <div className="searchList">
                <div className="wrapper">
                    {
                        (props.urlTemplate && props.result && props.result.length && (props.result.map((item, index) => {
                            let link = stringVariableExpand(props.urlTemplate, {
                                AliasId: item.AliasId,
                                CategoryId: item.CategoryId,
                                CellId: item.CellId
                            });
                            return <Cell key={index} item={item} title={item.Category} linkTo={link} type={"stamp"}/>
                        }))) || <div>Nothing found!</div>
                    }
                </div>
            </div>
        )
    } else {
        return <div>Loading...</div>
    }
}

SearchList.propTypes = {
    result: PropTypes.array,
    urlTemplate: PropTypes.string
};

function SearchPage(props) {
    let { range, key } = useParams();
    let queries = new URLSearchParams(useLocation().search);
    let country = queries.get("country");
    let sector = queries.get("sector");
    let filter = queries.get("filter");
    let aliasId = queries.get("id");

    const [title, setTitle] = useState("Search");
    const [urlTemplate, setUrlTemplate] = useState();
    const [response, setResponse] = useState(null);
    useEffect(() => {
        let theKey = key===undefined ? "" : key;
            let url = "/api/search/" + range + "/" + theKey;
            if (country || sector || filter) {
                url = url + "?country=" + (country ? country : "") + "&sector=" + (sector ? sector : "") + "&filter=" + (filter ? filter : "") + "&id=" + (aliasId ? aliasId : "");
            }
            console.log("url: " + url);
            fetch(url)
                .then(res => res.json())
                .then(res => {
                    setUrlTemplate(res.urlTemplate);
                    setResponse(res.data);
                    if (res.filterTitle && res.filterTitle !== "") {
                        setTitle(res.filterTitle);
                    } else if (["featured", "updated"].includes(range)) {
                        setTitle(range);
                    } else {
                        setTitle("Search");
                    }
                })
                .catch(console.error)
    }, [range, key, country, sector, filter, aliasId]);
    let titleLink = aliasId ? "/grid/"+aliasId : "/search/post";
    return (
        <div className={"home search " + range}>
            <QuickNavTitle
                title={title}
                titleLink={titleLink}
                toPrev={""}
                subTitle={""}
                toNext={""}>
                {
                    (aliasId &&
                        <>
                            <DropdownItem><Link to={response && "/about/" + aliasId}>About</Link></DropdownItem>
                            <DropdownItem><Link to={response && "/updated/" + aliasId}>Updated</Link></DropdownItem>
                            <DropdownItem><Link to={response && "/grid/" + aliasId}>Subjects</Link></DropdownItem>
                        </>
                    )
                }
            </QuickNavTitle>
            <div className="container">
                <SearchInputField range={range} />
            </div>
            <SearchResult result={response} filter={filter} urlTemplate={urlTemplate} />
        </div>
    );
}

export default SearchPage;