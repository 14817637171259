import {Link, withRouter} from "react-router-dom";
import React from "react";
import "./expandableText.css"

function ExpandableText(props) {
    return <Link to={props.link}>
        <div className={"expandable"}>
        {
            ( props.text && props.text.split('\n').map( (item, key) => {
                return <span key={key}>{item}<br/></span>
            })
            )
        }
        </div>
    </Link>
}

export default withRouter(ExpandableText)