import React from "react";
import {Cell} from "./@components";
import {withRouter} from "react-router-dom";

function CellList(props) {
    if (props.itemArray) {
        if (props.itemArray.length > 0) {
            return (
                <div className="cellList">
                    {
                        (
                            props.itemArray.map((item, index) => {
                                const link = (props.linkPrefix || "") + item[props.linkItemField || "ImageUrl"]
                                return <Cell key={index} item={item} linkTo={link} type={props.type || "about"}/>
                            })
                        )
                    }
                </div>
            )
        } else {
            return <span>{props.emptyMessage || "No data"}</span>
        }
    } else {
        return <span>Loading...</span>
    }
}

export default withRouter(CellList);