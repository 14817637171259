import React, { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import {Cell, QuickNavTitle} from "../components";
import "./home.css";
import "./cellPage.css";
import {DropdownItem} from "reactstrap";

function CellPage(props) {
    let { id } = useParams();
    const [response, setResponse] = useState();
    const [prevLink, setPrevLink] = useState("");
    const [nextLink, setNextLink] = useState("");
    useEffect(() => {
        fetch("/api/cell/" + id)
            .then(res => res.json())
            .then(res => {
                setResponse(res.data);
                if (res.prevCellId !== -1) {
                    setPrevLink("/cell/" + res.prevCellId);
                } else {
                    setPrevLink("");
                }
                if (res.nextCellId !== -1) {
                    setNextLink("/cell/" + res.nextCellId);
                } else {
                    setNextLink("");
                }
            })
            .catch(console.error)
    }, [id]);

    return (
        <div className="cellView">
            <QuickNavTitle
                title={response && response.length && response[0].Alias}
                titleLink={response && response.length && ( "/grid/" + response[0].AliasId )}
                toPrev={prevLink}
                subTitle={""}
                toNext={nextLink}>
                    {
                        (response && response.length && response[0].AliasId &&
                            <>
                                <DropdownItem><Link to={response && response.length && "/about/" + response[0].AliasId}>About</Link></DropdownItem>
                                <DropdownItem><Link to={response && response.length && "/updated/" + response[0].AliasId}>Updated</Link></DropdownItem>
                                <DropdownItem><Link to={response && response.length && "/search/alias?filter=associated&id=" + response[0].AliasId}>Associated</Link></DropdownItem>
                                <DropdownItem><Link to={response && response.length && "/grid/" + response[0].AliasId}>Subjects</Link></DropdownItem>
                            </>
                        )
                    }
            </QuickNavTitle>
            <div className="container">
                <div className="cellList">
                    {
                        ( response && response.length && ( response.map((item, index) => {
                            return <Cell key={index} item={item} linkTo={""} type={"single"} />
                        })) ) || "Loading..."
                    }
                </div>
            </div>
        </div>
    );
}

export default CellPage;